import React, { useState } from "react";
import axios from "axios";
// @material-ui/core components
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import SEO from 'react-seo-component';
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import { Redirect } from 'react-router-dom';


import styles from "assets/jss/material-kit-react/views/contact";

const useStyles = makeStyles(styles);

export default function Contact(props) {
  const classes = useStyles();
  
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [subject, setSubject] = useState();
  const [body, setBody] = useState();
  const [redirect, setRedirect] = useState(false);
  const path = 'https://haltonparentsforchange.ca/Contact/';
  const dashboardRoutes = [];
  const { ...rest } = props;
  
  
  async function submit() {
    console.log(name);
    console.log(email);
    console.log(body);

    let params = {
      subject: subject,
      replyTo:email,
      html: "New Contact from: <b>" + name + "</b><br/><br/>Subject: <b>" + subject + "</b><br/><br/><b>"+body+"</b>",      
    }

    await axios.post('https://hcdsb-strapi.fly.dev/contact', params).catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        return
      }
    });

    setBody('');
    setName('');
    setEmail('');
    setSubject('');
    setRedirect(true);
    
  }
  
  return (
    <div>
      { redirect ? (<Redirect push to="/"/>) : null }
      <SEO
        title='Contact Us'
        titleTemplate='Halton Parents for Change'
        titleSeparator={`-`}
        description={'Personal profile of {{person.Name}} and their relations to the Halton Catholic District School Board - HCDSB'}
        pathname={path}
        siteLanguage='en'
        siteLocale='en-CA'
      />
      <Header
        color="dark"
        routes={dashboardRoutes}
        brand="Halton Parents for Change"
        rightLinks={<HeaderLinks />}
        fixed
        {...rest}
      />

      <Parallax image={require("assets/img/mwesigwa-joel-jHvedTh-avo-unsplash2.jpg")} small={true}/>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={classes.topsection}>
          
            <h2 className={classes.title}>Contact Us</h2>
            <h4 className={classes.description}>
              If you'd like to share your thoughts, opinions or otherwise need to get in touch, feel free to contact us below.
            </h4>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                  <CustomInput
                    labelText="Your Name"
                    id="name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    value={setName}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <CustomInput
                    labelText="Your Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    value={setEmail}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <CustomInput
                    labelText="Subject"
                    id="subject"
                    formControlProps={{
                      fullWidth: true
                    }}
                    value={setSubject}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <CustomInput
                    labelText="Message"
                    id="message"
                    formControlProps={{
                      fullWidth: true
                    }}
                    value={setBody}
                    multiline={true}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <Button onClick={submit} color="primary">Submit</Button>
                </GridItem>
                  
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
