import React, { useState, useEffect } from "react";
import axios from "axios";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import { useParams } from 'react-router-dom';
import moment from 'moment';
import SEO from 'react-seo-component';
import { ArrowBack } from "@material-ui/icons";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import ReactMarkdown from 'react-markdown';
import "lightgallery.js/dist/css/lightgallery.css";
import {
    LightgalleryProvider,
    LightgalleryItem,
  } from "react-lightgallery";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

const PhotoItem = ({ image, thumb, group }) => (
    <div style={{ maxWidth: "250px", width: "200px", padding: "5px" }}>
      <LightgalleryItem group={group} src={image} thumb={thumb}>
        <img src={image} alt="" style={{ width: "100%" }} />
      </LightgalleryItem>
    </div>
  );


export default function Article(props) {
  const [article, setArticle] = useState();
  const [galley, setGallery] = useState(false);
  const classes = useStyles();
  const { ...rest } = props;
  let { id } = useParams();
  const dashboardRoutes = [];

  const path = 'https://haltonparentsforchange.ca/Article/'.concat(article != null ? article.slug : '');
  
  const imageClasses = classNames(
    classes.imgRaised,

    classes.imgFluid
  );

  useEffect(() => {
    const fetchArticle = () => {
      const article = axios.get(`https://hcdsb-strapi.fly.dev/articles?slug=${id}`);
      
      axios.all([article]).then(
        axios.spread((...allData) => {
          setArticle(allData[0].data[0]);
        })
      );
     
    };

    fetchArticle();
  }, [id])
  if (article === undefined){
    return (<div/>)
  }
  return (
    <div>
      <SEO
        title={article.Title}
        titleTemplate='Halton Parents for Change'
        titleSeparator={`-`}
        description={article.description}
        pathname={path}
        siteLanguage='en'
        siteLocale='en-CA'
      />
      {galley === false ?
      <Header
        color="dark"
        routes={dashboardRoutes}
        brand="Halton Parents for Change"
        rightLinks={<HeaderLinks />}
        fixed
        {...rest}
      /> : <div/>}

      <Parallax image={require("assets/img/mwesigwa-joel-jHvedTh-avo-unsplash2.jpg")} small={true}/>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.article}>
        <Link to="/News" >
          <Button color="transparent" className={classes.buttonLink}>
            <ArrowBack /> News
          </Button>
        </Link>
        <h5 className={classes.time}>{moment(article.news_date).format("LL")}</h5>
          <div className={classes.container}>
            <GridContainer justify="center" alignItems="center">
              <GridItem xs={12} sm={12} md={9}>
                <div className={classes.article}>
                    <h2 className={classes.title}>{article.Title}</h2>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <div className={classes.article}>
                    <img src={article.thumbnail.url} alt="" className={imageClasses} />
                </div>
               </GridItem>
            </GridContainer>
            
            <h4 className={classes.articleBody}><ReactMarkdown>{article.content }</ReactMarkdown></h4>

            <LightgalleryProvider
                onAfterOpen={() => {setGallery(true);}}
                onCloseAfter={() => {setGallery(false);}}
            >
                {article.image !== undefined ?
                <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
                >
                {article.image.map(function(p, idx)  {
                    return (<PhotoItem key={idx} image={p.url} thumb={p.url} group="test" />)
                })}
                </div>
                : <div/> }
            </LightgalleryProvider>
          </div>

         </div>
      </div>
      <Footer />
    </div>
  );
}
