import { container, title } from "assets/jss/material-kit-react.js";

import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";
import {  defaultFont } from "assets/jss/material-kit-react.js";

const contactStyle = theme => ({
  container,
  profile: {
    textAlign: "center",
    "& img": {
      maxWidth: "250px",
      width: "100%",
      margin: "0 auto",
      transform: "translate3d(0, -50%, 0)"
    }
  },
  description: {
    margin: "1.071rem auto 0",
    maxWidth: "600px",
    color: "#999",
    textAlign: "center !important",
    "& > ol": {
      textAlign: "justify"
    },
    
  },
  name: {
    marginTop: "-125px"
  },
  ...imagesStyle,
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  title: {
    ...title,
    position: "relative",
    marginTop: "0px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center"
  },
  location: {
    marginTop: "0px",
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999"
  },
  navWrapper: {
    margin: "20px auto 30px auto",
    textAlign: "center"
  },
  leftText:{
    textAlign: "left",
    padding: "0px 10px"
  },
  speedometer:{
    [theme.breakpoints.down('sm')]: {
      paddingTop:"0px", 
      textAlign:"center"
    },
    [theme.breakpoints.up('md')]: {
      paddingTop:"50px", 
      textAlign:"left"
    },
    
  },
  input: {
    color: "#495057",
    height: "unset",
    "&,&::placeholder": {
      fontSize: "14px",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: "400",
      lineHeight: "1.42857",
      opacity: "1"
    },
    "&::placeholder": {
      color: "#AAAAAA"
    }
  },
  labelRoot: {
    ...defaultFont,
    color: "#AAAAAA !important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "1.42857",
    top: "10px",
    letterSpacing: "unset",
    "& + $underline": {
      marginTop: "0px"
    }
  },
  section: {
    padding: "70px 0",
    textAlign: "center"
  },
  topsection: {
    padding: "70px 0px 0px 0px",
    textAlign: "center"
  },
  heading: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightBold,
  },
  fullHeightCard: {
    height: "100%",
    },
  brandLogo: {
    maxHeight: "200px",
    maxWidth:"80%",
    padding:"20px"
  },
  buttonLink: {
    "&,&:hover,&:focus": {
      color: "#3C4858",
      textDecoration: "none" ,
      padding: "0.9375rem",
      margin: "0px",
      fontWeight: "400",
      fontSize: "18px"
    },
    "&:hover,&:focus": {
      color: "#3C4858 !important",
      background: "rgba(80, 80, 80, 0.2)"
    },
    "&:visited": {
      textDecoration: "none",
      color: "#3C4858"
    },
  },
  cardFooter: {
    color: "#999",
    textAlign:"left",
    padding:"0rem 0rem",
    marginTop:"1px",
    marginBottom: "0px",
    fontSize: "0.8rem",
    
  },
  personName: {
    textAlign:"center",
    padding:"0rem 6rem",
    color: "#3C4858",
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
    fontSize:"2rem",
    fontWeight: 500,
    marginTop: "5px",
    marginBottom: "5px",
    [theme.breakpoints.down("sm")]: {
      padding:"0rem 2rem",
      
    },
    [theme.breakpoints.down("xs")]: {
      padding:"0rem 0rem",
      
    }
  },
  bio: {
    textAlign:"left",
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
    fontSize:"1.1rem",
    fontWeight: 500,
    marginTop: "5px",
    marginBottom: "5px",
    [theme.breakpoints.down("sm")]: {
      padding:"0rem 2rem",
      
    },
    [theme.breakpoints.down("xs")]: {
      padding:"0rem 0rem",
      
    }
  },
});

export default contactStyle;
