import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import AppContainer from 'AppContainer.js';
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import Board from "views/Board/Board.js";
import Contact from "views/Contact/Contact.js";
import Issues from "views/Issues/Issues.js";
import About from "views/About/About.js";
import Election from "views/Election/Election.js";
import News from "views/News/News.js";
import Article from 'views/News/Article.js';
// import Covid from 'views/Covid/Covid';
// import CovidReporting from 'views/Covid/CovidReporting';
// import Volunteer from 'views/Volunteer/Volunteer';
// import ElectionInfo from 'views/ElectionInfo/ElectionInfo.js';
// import CandidateProfile from 'views/Candidate/CandidateProfile.js';

var hist = createBrowserHistory();


ReactDOM.render(
  <div>
    <Router history={hist}>
      <AppContainer/>
      <Switch>
          <Route path='/News' component={News} />
          <Route path='/Article/:id' component={Article} />
          <Route path='/Article/*' component={Article} />
          <Route path="/Board" component={Board} />
          <Route path="/ProfilePage/:id" component={ProfilePage} />
          <Route path="/ProfilePage/*" component={ProfilePage} />
          <Route path="/Contact/" component={Contact}/>
          <Route path="/Issues/" component={Issues}/>
          <Route path="/About/" component={About}/>
          <Route path="/Election/" component={Election}/>
          {/* <Route path="/Covid/" component={Covid}/> */}
          {/* <Route path="/Reporting/" component={CovidReporting}/> */}
          {/* <Route path="/Volunteer/" component={Volunteer}/> */}
          {/* <Route path="/ElectionInfo" component={ElectionInfo}/> */}
          {/* <Route path="/CandidateProfile/:id" component={CandidateProfile} />
          <Route path="/CandidateProfile/*" component={CandidateProfile} />
         */}
          
          <Route path="/" component={LandingPage} />
      </Switch>
    </Router>
  </div>,
  document.getElementById("root")

  
);
