import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import FlagIcon from '@material-ui/icons/Flag';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import ForumIcon from '@material-ui/icons/Forum';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import { Link } from "react-router-dom";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function MissionStatement() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Mission Statement</h2>
          <h5 className={classes.description}>
            For several years the Halton Catholic District School Board has been dysfunctional.  Trustees have put personal agendas ahead of the best interests of staff, 
            students and the community.  These attitudes have allowed a culture of discrimination to take hold impacting the community of staff, students, and parents.  Halton Parents for Change aims to keep them accountable for their actions and advocate for the community when trustees 
            will not.
          </h5>
          <h5 className={classes.description}>
            This page is not affiliated with any board member, electoral candidate or the Halton Catholic District School Board.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Link to="/Issues">
              <InfoArea
                title="Major Issues"
                description="Summaries of the key issues facing the board and highlighting where each trustee stands"
                icon={ForumIcon}
                iconColor="info"
                vertical
              />
            </Link>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Link to="/Board">
              <InfoArea
                title="Current Board"
                description="Keep track of voting records, motions, quotes and conduct within the board room including rating of their performance"
                icon={FlagIcon}
                iconColor="danger"
                vertical
              />
            </Link>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Link to="/">
              <InfoArea
                title="2022 Election"
                description="Leading up to the 2022 Provincial Election, track all of the registered candidates and their positions on issues pertinent to the board."
                icon={HowToVoteIcon}
                iconColor="warning"
                vertical
              />
            </Link>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
