const cardFooterStyle = {
  cardFooter: {
    display: "flex",
    flexDirection:"column",
    alignItems: "center",
    backgroundColor: "transparent",
    padding: "0.9375rem 1.875rem"
  },

  description: {
    color: "#999",
    textAlign: "center"
  },
};

export default cardFooterStyle;
