/*eslint-disable*/
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import moment from 'moment';
import classNames from "classnames";

// react components for routing our app without refresh
import { createBrowserHistory } from "history";

import SEO from 'react-seo-component';
import { Link } from "react-router-dom";
import { Router, Route, Switch } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import ProfilePage from "views/ProfilePage/ProfilePage.js";


// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/board.js";
import { Person } from "@material-ui/icons";
const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function News(props) {
  const [articles, setArticles] = useState([]);
  const classes = useStyles();
  const { ...rest } = props;
  
  const fetchArticles = () => {
    const articles = axios.get("https://hcdsb-strapi.fly.dev/articles?_sort=news_date:DESC");
    axios.all([articles]).then(
      axios.spread((... allData) => {
        let articles = [];
        articles = articles.concat (allData[0].data);
        setArticles(articles);
      })
    );
  };
  
  useEffect(() => {
    fetchArticles();
    return () => {
      setArticles(null); // This worked for me
    };
  }, [])

  var hist = createBrowserHistory();

  return (
    <div>
      <SEO
        title='Halton Parents for Change News'
        titleTemplate='Halton Parents for Change'
        titleSeparator={`-`}
        description={'News and events of Halton Parents for Change and Halton Catholic District School Board - HCDSB'}
        pathname='https://haltonparentsforchange.ca/News'
        siteLanguage='en'
        siteLocale='en-CA'
      />
      <Header
        color="dark"
        routes={dashboardRoutes}
        brand="Halton Parents for Change"
        rightLinks={<HeaderLinks />}
        fixed
        {...rest}
      />
      <Parallax image={require("assets/img/mwesigwa-joel-jHvedTh-avo-unsplash2.jpg")} small={true}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>News & Updates</h1>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
      
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          {articles.map(function(article){
            return (
              <GridItem key={article.slug} xs={12} sm={6} md={4}>
                <Card className= {classes.newsCard} style={{alignConent:'center', justifyContent: 'center'}}>
                    <Link to={`../Article/${article.slug}`} className={classes.link}>
                      <div style={{width:'80%', margin:'auto'}}>
                      <h5 className={classes.time}>{moment(article.news_date).format("LL")}</h5>
                      {article.thumbnail != null ?
                      <img
                        width="100%"
                        src={article.thumbnail.url}
                        alt="..."
                        className={
                          classes.imgRaised +
                          " " +
                          classes.imgRounded +
                          " " +
                          classes.imgFluid
                        }
                      />
                      :
                      <img
                        width="100%"
                        src={window.location.origin + '/img/HPFC_Logo_Full.png'}
                        alt="Halton Parents for Change logo"
                        className={
                          classes.imgRaised +
                          " " +
                          classes.imgRounded +
                          " " +
                          classes.imgFluid
                        }
                      />

                      }

                      </div>
                      <CardFooter  className={classes.cardFooter}>
                           <h4 className={classes.newsTitle}>
                             <span>
                               {article.Title}
                               </span>
                            </h4>
                        <h5 className={classes.description}>{article.description}</h5>
                      </CardFooter>
                    </Link>
                  </Card>
                </GridItem>
            )
          
          })}
        </GridContainer>
      </div>
    </div>
    </div>
    </div>
  );
}
