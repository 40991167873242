import React, { useEffect }          from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA                       from 'react-ga';

//Unique Google Analytics tracking number
ReactGA.initialize('UA-205162390-1'); 

const App = (props) => {

    useEffect( () => {

        // This line will trigger on a route change
        ReactGA.pageview(window.location.pathname + window.location.search); 

    });

    return (
       <div>{props.children}</div>
    );

}

export default withRouter(App);