/*eslint-disable*/
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import classNames from "classnames";

// react components for routing our app without refresh
import { createBrowserHistory } from "history";

import SEO from 'react-seo-component';
import { Link } from "react-router-dom";
import { Router, Route, Switch } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import ProfilePage from "views/ProfilePage/ProfilePage.js";


// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/board.js";
import { Person } from "@material-ui/icons";
const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function Board(props) {
  const [people, setPeople] = useState([]);
  const [board2018, setBoard2018] = useState([]);
  const classes = useStyles();
  const { ...rest } = props;
  
  const fetchPeople = () => {
    const trustees = axios.get("https://hcdsb-strapi.fly.dev/people?Role=Trustee&BoardArchives.IsCurrent=true&_sort=City:ASC,ShortName:ASC");
    const board2018 = axios.get("https://hcdsb-strapi.fly.dev/people?Role=Trustee&BoardArchives.IsCurrent=false&BoardArchives.StartYear=2018&_sort=City:ASC,ShortName:ASC");
    axios.all([trustees, board2018]).then(
      axios.spread((... allData) => {
        let people = [];
        people = people.concat (allData[0].data);
        setPeople(people);
        let board2018 = [];
        board2018 = board2018.concat(allData[1].data);
        setBoard2018(board2018);
      })
    );
  };
  
  useEffect(() => {
    fetchPeople();
    return () => {
      setPeople(null);
    };
  }, [])

  var hist = createBrowserHistory();

  return (
    <div>
      <SEO
        title='Board of Trustees'
        titleTemplate='Halton Parents for Change'
        titleSeparator={`-`}
        description={'Current board of trustees of the Halton Catholic District School Board - HCDSB'}
        pathname='https://haltonparentsforchange.ca/Board'
        siteLanguage='en'
        siteLocale='en-CA'
      />
      <Header
        color="dark"
        routes={dashboardRoutes}
        brand="Halton Parents for Change"
        rightLinks={<HeaderLinks />}
        fixed
        {...rest}
      />
      <Parallax image={require("assets/img/mwesigwa-joel-jHvedTh-avo-unsplash2.jpg")} small={true}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>Halton Catholic Board of Trustees</h1>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
      
    <div className={classes.section}>
      <div className={classes.container}>
      <h2 className={classes.subHeading}>Current Board</h2>
        
        <GridContainer justify="center">
          {people.map(function(person){
            return (
              <GridItem key={person.id} xs={12} sm={6} md={4}>
                <Card className= {classes.card} style={{alignConent:'center', justifyContent: 'center'}}>
                    <Link to={`../ProfilePage/${person.ShortName}`} className={classes.link}>
                      <div style={{width:'60%', margin:'auto'}}>
                      <img
                        width="100%"
                        src={person.Photo[0].url}
                        alt="..."
                        className={
                          classes.imgRaised +
                          " " +
                          classes.imgRounded +
                          " " +
                          classes.imgFluid
                        }
                      />

                      <h5 className={classes.cardFooter}>{person.Photo[0].caption}</h5>       
                      </div>
                      <CardFooter  className={classes.cardFooter}>
                           <h2 className={classes.personName}>
                             <span>
                               {window.innerWidth > 1000 ? person.Name.replace('-', String.fromCharCode(8209)) : person.Name}
                               </span>
                            </h2>
                        <h4 className={classes.description}><b>{person.City.replace(/([A-Z])/g, ' $1').trim()} {person.Role}</b></h4>
                      </CardFooter>
                    </Link>
                  </Card>
                </GridItem>
            )
          
          })}
        </GridContainer>
      </div>

      <hr/>

      <div className={classes.container}>
        <h2 className={classes.subHeading2}>Board Archive</h2>
        <h2 className={classes.subHeading3}>2018-2022</h2>
        
        <GridContainer justify="center">
          {board2018.map(function(person){
            return (
              <GridItem key={person.id} xs={4} sm={3} md={2}>
                <Card className= {classes.card} style={{alignConent:'center', justifyContent: 'center'}}>
                    <Link to={`../ProfilePage/${person.ShortName}`} className={classes.link}>
                      <div style={{width:'60%', margin:'auto'}}>
                      <img
                        width="100%"
                        src={person.Photo[0].url}
                        alt="..."
                        className={
                          classes.imgRaised +
                          " " +
                          classes.imgRounded +
                          " " +
                          classes.imgFluid
                        }
                      />

                      <h5 className={classes.cardFooter}>{person.Photo[0].caption}</h5>       
                      </div>
                      <CardFooter  className={classes.cardFooter}>
                           <h2 className={classes.personNameMini}>
                             <span>
                               {window.innerWidth > 1000 ? person.Name.replace('-', String.fromCharCode(8209)) : person.Name}
                               </span>
                            </h2>
                        <h4 className={classes.description}><b>{person.City.replace(/([A-Z])/g, ' $1').trim()} {person.Role}</b></h4>
                      </CardFooter>
                    </Link>
                  </Card>
                </GridItem>
            )
          
          })}
        </GridContainer>
      </div>

    </div>
    </div>
    </div>
  );
}
