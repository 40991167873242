import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MailchimpSubscribe from "react-mailchimp-subscribe";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);
const Checkbox = props => (
  <input type="checkbox" {...props} />
)
const CustomForm = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState(''); // '' is the initial state value
  const [name, setName] = useState(''); // '' is the initial state value
  const [consent, setConsent] = useState(false);
  
  const handleCheckboxChange = event => {
    console.log(event.target.checked)
    setConsent(event.target.checked)
  }
  const submit = () => 
    email &&
    name &&
    consent && 
    email.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email,
      NAME: name
    });

  return (
    <div>
      {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
      {status === "error" && (
        <div
          style={{ color: "red" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "green" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Your Name"
                  id="name"
                  formControlProps={{
                    fullWidth: true
                  }}
                  value={setName}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Your Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  value={setEmail}
                />
              </GridItem>
              <GridItem xs={12}>
                <label>
                    <Checkbox
                      id="consent"
                      checked={consent}
                      value={consent}
                      onChange={handleCheckboxChange}
                    />
                  <span>Yes, I'd like to receive email updates from Halton Parents for Change.  I understand that I can unsubscribe at any time.</span>
                 </label>
              </GridItem>
            
              <GridItem xs={12} sm={12} md={4}>
                <Button onClick={submit} color="primary">Subscribe</Button>
              </GridItem>
            </GridContainer>
    </div>
  );
};

export default function WorkSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Stay Connected</h2>
          <h4 className={classes.description}>
            From time to time we may reach out with updates and calls to action.
            If you'd like to stay informed, enter your information below and we'll be in touch.
          </h4>
          <MailchimpSubscribe
          url="https://gmail.us1.list-manage.com/subscribe/post?u=9e586538f0bd7ad464975af89&amp;id=7c659b5c6a"
          render={({ subscribe, status, message }) => (
            <CustomForm
              status={status}
              message={message}
              onValidated={formData => subscribe(formData)}
            />
          )}
        />
  
  
        </GridItem>
      </GridContainer>
    </div>
  );
}
