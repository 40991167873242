import { container, title } from "assets/jss/material-kit-react.js";

const landingPageStyle = theme => ({
  
  container: {
    zIndex: "12",
     ...container
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none"
  },
  description: {
    color: "#999",
    textAlign: "center"
  },
  time: {
    color: "#999",
    textAlign: "left"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  topSpace: {
    padding: "70px 0",
    textAlign: "center"
  },
  topsection: {
    padding: "0px 0px 0px 0px",
    textAlign: "center"
  },
  cardFooter: {
    color: "#999",
    textAlign:"center",
    padding:"0rem 0rem",
    marginTop:"1px",
    marginBottom: "0px",
    fontSize: "0.8rem",
    
  },
  cardButton: {
    maxWidth: "100%",
    whiteSpace: "break-spaces",
    padding: "1.125rem 1.125rem",
    fontSize: "1rem",
    lineHeight: "1.333333",
    borderRadius: "0.2rem"
  },
  personName: {
    textAlign:"center",
    padding:"0rem 6rem",
    color: "#3C4858",
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
    fontSize:"1.5rem",
    fontWeight: 500,
    marginTop: "5px",
    marginBottom: "5px",
    [theme.breakpoints.down("sm")]: {
      padding:"0rem 2rem",
      
    },
    [theme.breakpoints.down("xs")]: {
      padding:"0rem 0rem",
      
    }
  },
  brandLogo: {
    maxHeight: "200px",
    maxWidth:"80%",
    padding:"20px"
  },
  newsCard:{
    display: "inline-block",
    position: "relative",
    width: "100%",
    margin: "10px 10px",
    padding: "10px 10px",
    borderRadius: "3px",
    boxShadow: "0 0px 0px 0 rgba(0, 0, 0, 0.14)",
    color: "rgba(0, 0, 0, 0.87)",
    background: "#fff"
  },
  newsTitle: {
    textAlign:"center",
    padding:"0rem 1rem",
    color: "#3C4858",
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
    fontSize:"1.5rem",
    fontWeight: 500,
    marginTop: "5px",
    marginBottom: "5px",
    
  },
  
})
;

export default landingPageStyle;
