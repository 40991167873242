import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";

import styles from "assets/jss/material-kit-react/components/customInputStyle.js";
import { createMuiTheme, MuiThemeProvider, TextField } from "@material-ui/core";
import { colors } from '@material-ui/core';

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette:{
    primary:colors.grey,
  }
})

export default function CustomInput(props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    inputProps,
    multiline
  } = props;

  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  return (
    <FormControl {...formControlProps} className={formControlClasses}>
     <MuiThemeProvider theme={theme}>
      <TextField
            label={labelText}
            InputLabelProps={{
              style:{color:"#999999"}
            }}
            id={id}
            onChange={(evt) => { props.value(evt.target.value); }}
            {...inputProps}
            multiline={multiline}
            rowsMax={multiline?10:1}
            rows={multiline?10:1}
          />
        </MuiThemeProvider>
    </FormControl>
  );
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  value: PropTypes.func,
  multiline: PropTypes.bool
};
