import React from "react";
// @material-ui/core components
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import SEO from 'react-seo-component';
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import ReactMarkdown from 'react-markdown';

import styles from "assets/jss/material-kit-react/views/contact";


/*eslint-disable*/
import { useState, useEffect } from "react";
import axios from "axios";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardFooter from "components/Card/CardFooter.js";

const useStyles = makeStyles(styles);

export default function Contact(props) {
  const classes = useStyles();
  const [people, setPeople] = useState([]);

  const path = 'https://haltonparentsforchange.ca/Contact/';
  const dashboardRoutes = [];
  const { ...rest } = props;
  
  const fetchPeople = () => {
    const hpfc = axios.get("https://hcdsb-strapi.fly.dev/people?Role=HPfC&_sort=Score:DESC");
    axios.all([hpfc]).then(
      axios.spread((... allData) => {
        let people = [];
        people = people.concat (allData[0].data);
        setPeople(people);
      })
    );
  };

  useEffect(() => {
    fetchPeople();
    return () => {
      setPeople(null); 
    };
  }, [])

  return (
    <div>
      <SEO
        title='About Us'
        titleTemplate='Halton Parents for Change'
        titleSeparator={`-`}
        description={'History of Halton Parents for Change including goals, motivation, current initiatives, etc'}
        pathname={path}
        siteLanguage='en'
        siteLocale='en-CA'
      />
      <Header
        color="dark"
        routes={dashboardRoutes}
        brand="Halton Parents for Change"
        rightLinks={<HeaderLinks />}
        fixed
        {...rest}
      />

      <Parallax image={require("assets/img/mwesigwa-joel-jHvedTh-avo-unsplash2.jpg")} small={true}/>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={classes.topsection}>
          
            <h2 className={classes.title}>About Us</h2>
            <h4 className={classes.description}>
              <b>Halton Parents for Change</b> is a not for profit organization made up of parents of the Halton Catholic District School Board (HCDSB).   As parents, we were fed up with seeing the discriminatory practices and policies being put in place at HCDSB and joined together to speak with one collective voice that this behaviour would not be tolerated.
            </h4>
            <h4 className={classes.description}>
              <br />This parent organization was initially formed in direct response to HCDSB's handling of the Pride Flag and other 2SLGBTQ+ initiatives (originally under the name <i>Halton Parent Allies</i>) and the disrespect shown towards the community by certain board members.  Since that time our scope has widened as more discriminatory practices were brought to light and the HCDSB expressed an unwillingness to address the issues or affect meaningful change.
            </h4>
            <h4 className={classes.description}>
              <br />As such, our goals going forward are threefold:
              <ol>
                <li>Fighting for equity for all communities within the HCDSB through actions like organized demonstrations, campaigns, etc.</li>
                <li>Increasing public awareness of the issues which are currently affecting our board and those whom are responsible.</li>
                <li>Holding elected trustees accountable for their decisions in the coming municipal election.</li>
              </ol>
            </h4>
            
            <img alt="Halton Parents for Change logo" className={classes.brandLogo} src={window.location.origin + '/img/HPFC_Logo_Full.png'} />
     
        <GridContainer justify="center">
          {people.map(function(person){
            return (
              <GridItem key={person.id} xs={12} sm={6} md={3}>
                <Card className= {classes.card} style={{alignConent:'center', justifyContent: 'center'}}>
                      <div style={{width:'60%', margin:'auto'}}>
                      </div>
                      <CardFooter  className={classes.cardFooter}>
                           <h2 className={classes.personName}>
                             <span>
                               {window.innerWidth > 1000 ? person.Name.replace('-', String.fromCharCode(8209)) : person.Name}
                               </span>
                            </h2>
                        <h4 className={classes.bio}><ReactMarkdown>{person.Notes}</ReactMarkdown></h4>
                      </CardFooter>
                  </Card>
                </GridItem>
            )
          
          })}
        </GridContainer>
    
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
