import { container, title } from "assets/jss/material-kit-react.js";

import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

const profilePageStyle = theme => ({
  container,
  profile: {
    textAlign: "center",
    padding:"0px 0px 20px 0px",
    "& img": {
      maxWidth: "250px",
      width: "100%",
      margin: "0 auto",
      transform: "translate3d(0, -50%, 0)"
    }
  },
  bottom:{
    paddingBottom:"40px"
  },
  article: {
    padding:"10px",

    textAlign: "center",
    "& img": {
      maxWidth: "250px",
      width: "100%",
      margin: "0 auto",
    }
  },
  articleBody:{
    textAlign:"justify",
    whiteSpace:"pre-wrap",
    "& p" :{
      marginBlockStart:"0px",
      marginBlockEnd:"0px",
    }
  },
  buttonLink: {
    "&,&:hover,&:focus": {
      color: "#3C4858",
      textDecoration: "none" ,
      display: "block",
      padding: "0.9375rem",
      margin: "0px",
      fontWeight: "400",
      fontSize: "18px"
    },
    "&:hover,&:focus": {
      color: "#3C4858 !important",
      background: "rgba(80, 80, 80, 0.2)"
    },
    "&:visited": {
      textDecoration: "none",
      color: "#3C4858"
    },
  },
  description: {
    margin: "1.071rem auto 0",
    maxWidth: "600px",
    color: "#999",
    textAlign: "center !important"
  },
  name: {
    marginTop: "-125px"
  },
  ...imagesStyle,
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "0px",
    minHeight: "32px",
    textDecoration: "none"
  },
  location: {
    marginTop: "0px",
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999"
  },
  navWrapper: {
    margin: "20px auto 30px auto",
    textAlign: "center"
  },
  leftText:{
    textAlign: "left",
    padding: "0px 10px"
  },
  positionItem:{
    "& .MuiGrid-item":{
      padding: "12px 0px 0px 0px",
      margin:"0px"
    }
  },
  positions:{
    textAlign: "left",
    padding: "0px 0px 0px 10px",
    margin:"0px"
  },
  speedometer:{
    [theme.breakpoints.down('sm')]: {
      paddingTop:"0px", 
      textAlign:"center"
    },
    [theme.breakpoints.up('md')]: {
      paddingTop:"50px", 
      textAlign:"left"
    },
    
  }
});

export default profilePageStyle;
