import React, { useState, useEffect } from "react";
import axios from "axios";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { useParams } from 'react-router-dom';

import SEO from 'react-seo-component';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

// import Paper from "@material-ui/core/Paper/Paper.js"
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import ReactSpeedometer from 'react-d3-speedometer'

const useStyles = makeStyles(styles);
// const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export default function ProfilePage(props) {
  const [person, setPerson] = useState();
  const [motions, setMotions] = useState();
  const classes = useStyles();
  const { ...rest } = props;
  let { id } = useParams();
  const dashboardRoutes = [];
  const hasWindow = typeof window !== 'undefined';

  const path = 'https://haltonparentsforchange.ca/ProfilePage/'.concat(person != null ? person.ShortName : '');
  
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    return width;
  }

  useEffect(() => {
    const fetchPerson = () => {
      const person = axios.get(`https://hcdsb-strapi.fly.dev/people?ShortName=${id}`);
      const motions = axios.get(`https://hcdsb-strapi.fly.dev/motions`);
      
      axios.all([person, motions]).then(
        axios.spread((...allData) => {
          setPerson(allData[0].data[0]);
          setMotions(allData[1].data)
        })
      );
     
    };

    fetchPerson();
  }, [id])
  if (person === undefined){
    return (<div/>)
  }
  return (
    <div>
      <SEO
        title={person.Name}
        titleTemplate='Halton Parents for Change'
        titleSeparator={`-`}
        description={'Personal profile of {{person.Name}} and their relations to the Halton Catholic District School Board - HCDSB'}
        pathname={path}
        siteLanguage='en'
        siteLocale='en-CA'
      />
      <Header
        color="dark"
        routes={dashboardRoutes}
        brand="Halton Parents for Change"
        rightLinks={<HeaderLinks />}
        fixed
        {...rest}
      />

      <Parallax image={require("assets/img/mwesigwa-joel-jHvedTh-avo-unsplash2.jpg")} small={true}/>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={false} sm={false} md={4} />
              <GridItem xs={12} sm={12} md={4}>
                <div className={classes.profile}>
                  <div>
                    <img src={person.Photo[0].url} alt="" className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h2 className={classes.title}>{person.Name.replace('-', String.fromCharCode(8209))}</h2>
                    <h3 className={classes.location}>{person.City.replace(/([A-Z])/g, ' $1').trim()} {person.Role}</h3>
                    <h5>{person.Photo[0].caption}</h5>
                  </div>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={4} >
                <div className={classNames(classes.container, classes.speedometer)}>
          
                  <ReactSpeedometer
                    maxSegmentLabels={0}
                    width={getWindowDimensions() < 400 ? 250 : 300}
                    needleHeightRatio={0.7}
                    minValue={0}
                    maxValue={100}
                    value={person.Score}
                    currentValueText="Rating"
                    ringWidth={47}
                    needleTransitionDuration={2000}
                    needleTransition="easeLinear"
                    needleColor={'#90f2ff'}
                    textColor={'#000000'}
                  />
                </div>
              </GridItem>
            </GridContainer>
            
            <h3><b>Overall Opinion:</b></h3>
            <h4>{person.Notes === null ? "We don't have an opinion on this individual yet.  Please wait until more information is availabile." : person.Notes }</h4>
            

            <h3><b>Key Motions:</b></h3>
            <GridContainer spacing={3}>
              {
              (person!==undefined && motions!==undefined && person.motion_responses.length) ? 
                person.motion_responses.map(function(response){
                  return (
                    <GridItem key={response.id} xs={12}>
                        <h5 className={classes.leftText} ><b>{motions.filter(x=>x.id === response.motion)[0].Title}</b><br/>{response.Notes}</h5>
                      {/* <Paper elevation={5}>
                      </Paper> */}
                    </GridItem>
                  )}
                )
              : <GridItem    xs={12}><h5  className={classes.leftText} >No key motions found</h5></GridItem>
                  }
            </GridContainer>

            {/* <h3><b>Quotes:</b></h3>
            <GridContainer spacing={3}>
              {
              person.quotes.length ? 
                person.quotes.map(function(quote){
                  return (
                    <GridItem key={quote.id} xs={12}>
                        <h5  className={classes.leftText} >{quote.Quotation}</h5>
                      
                    </GridItem>
                  )}
                )
              : <GridItem xs={12}><h5  className={classes.leftText} >No quotations found</h5></GridItem>
                  }
            </GridContainer> */}

            <h3><b>Positions Held:</b></h3>
            <GridContainer className={classes.bottom} spacing={3}>
              {
              person.positions.length ? 
                person.positions.map(function(position){
                  return (
                    <GridItem style={{padding:'12px 0px 0px 12px'}} key={position.id} xs={12}>
                        <h5 className={classes.positions} >{position.Title}</h5>
                    </GridItem>
                  )}
                )
              : <GridItem xs={12}><h5  className={classes.leftText} >No positions found</h5></GridItem> 
                  }
            </GridContainer>
            
          </div>

            {/* <div className={classes.container}>
              <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                >
                    {person.statistics.map(stat => (
                        <Grid item xs={12} sm={6} md={3} key={stat.id}>
                            <Card raised>
                                <CardHeader
                                    title={stat.Description}
                                />
                                <CardContent>
                                    <Typography variant="h2" gutterBottom>
                                        {stat.Value}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <br/>
            </div>
             */}
         </div>
      </div>
      <Footer />
    </div>
  );
}
