import React, { useState, useEffect } from "react";
import axios from "axios";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from '@material-ui/core/styles';

import { useParams } from 'react-router-dom';

import SEO from 'react-seo-component';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/contact";

import {Typography, Card, CardContent, Grid } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

const useStyles = makeStyles(styles);

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function ProfilePage(props) {
  const [person, setPerson] = useState();
  const [motions, setMotions] = useState();
  const classes = useStyles();
  const { ...rest } = props;
  let { id } = useParams();
  const dashboardRoutes = [];
  
  const path = 'https://haltonparentsforchange.ca/ProfilePage/'.concat(person != null ? person.ShortName : '');
  

  useEffect(() => {
    const fetchPerson = () => {
      const person = axios.get(`https://hcdsb-strapi.fly.dev/people?Role=Trustee`);
      const motions = axios.get(`https://hcdsb-strapi.fly.dev/motions?_sort=Priority:ASC`);
      
      axios.all([person, motions]).then(
        axios.spread((...allData) => {
          setPerson(allData[0].data);
          setMotions(allData[1].data)
        })
      );
     
    };

    fetchPerson();
  }, [id])
  if (person === undefined){
    return (<div/>)
  }
  return (
    <div>
      <SEO
        title='Major Issues'
        titleTemplate='Hatlon Catholic Trustees'
        titleSeparator={`-`}
        description={'Major issues which have been debated at the Halton Catholic District School Board (HCDSB)'}
        pathname={path}
        siteLanguage='en'
        siteLocale='en-CA'
      />
      <Header
        color="dark"
        routes={dashboardRoutes}
        brand="Halton Parents for Change"
        rightLinks={<HeaderLinks />}
        fixed
        {...rest}
      />

      <Parallax image={require("assets/img/mwesigwa-joel-jHvedTh-avo-unsplash2.jpg")} small={true}/>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          
          <div className={classes.container}>
          <div className={classes.topsection}>
            <h2 className={classes.title}>Major Issues</h2>
            <h4 className={classes.description}>
            Below is a list of major issues and motions which have been brought before the Board of Trustees at Halton Catholic District School Board in the last few years.  Included are some of the main points of trustee's positions on these issues or other relevant information.
            </h4>
            </div>
            <div className={classes.section}>
                {
                  (person!==undefined && motions!==undefined && motions.length) ? 
                      motions.map(function(motion){
                        return (
                          <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className={classes.heading}>{motion.Title}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container alignItems="stretch" spacing={1} justify="space-evenly">
                              <Grid item xs={12}>
                              <Typography className={classes.description}>
                                {motion.Notes.split('\n').map((i, key) => {
                                  return <div key={key}>{i}</div>})
                      }
                              </Typography>
                              </Grid>
                              <Grid item xs={12} />
                              { 
                                motion.motion_responses.map(function(response){
                                  return (
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                      <Card raised className={classes.fullHeightCard}>
                                      <CardContent>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                          {person.filter(x=>x.id === response.person)[0].Name}
                                        </Typography>
                                        <Typography component="p">
                                          {response.Notes}
                                        </Typography>
                                      </CardContent>
                                      </Card>
                                    </Grid>
                                )})
                              }
                            </Grid>
                          </AccordionDetails>
                          </Accordion>
                        )
                      })
                    : null
                }
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
