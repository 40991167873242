import React, { useState, useEffect } from "react";

// @material-ui/core components
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import axios from "axios";
// core components
import SEO from 'react-seo-component';
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardFooter from "components/Card/CardFooter.js";

import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import { Link } from "react-router-dom";

import styles from "assets/jss/material-kit-react/views/election.js";

const useStyles = makeStyles(styles);

export default function Contact(props) {
  const classes = useStyles();
  
  const path = 'https://haltonparentsforchange.ca/Election/';
  const dashboardRoutes = [];
  const { ...rest } = props;
  

  const [people, setPeople] = useState([]);
  
  const fetchPeople = () => {
    const trustees = axios.get("https://hcdsb-strapi.fly.dev/Candidates?_sort=Ward:ASC,LastName:ASC");
    axios.all([trustees]).then(
      axios.spread((...allData) => {
        let people = [];
        people = people.concat (allData[0].data);
        setPeople(people);
      })
    );
  };
  
  useEffect(() => {
    fetchPeople();
    return () => {
      setPeople(null);
    };
  }, [])

  function candidateCard(person){
    return (
                  <GridItem key={person.id} xs={6} sm={3} md={2}>
                    <Card className= {classes.card} style={{alignConent:'center', justifyContent: 'center'}}>
                      <Link to={`../CandidateProfile/${person.ShortName}`} className={classes.link}>
                        <div style={{width:'60%', margin:'auto'}}>
                        <img
                          width="100%"
                          src={person.Photo.url}
                          alt="..."
                          className={
                            classes.imgRaised +
                            " " +
                            classes.imgRounded +
                            " " +
                            classes.imgFluid
                          }
                        />

                      <h5 className={classes.cardFooter}>{person.Photo.caption}</h5>       
                      </div>
                      <CardFooter  className={classes.cardFooter}>
                           <h2 className={classes.personName}>
                             <span>
                               {person.FirstName + ' ' + person.LastName}
                               </span>
                            </h2>
                        
                      </CardFooter>
                    </Link>
                  </Card>
                </GridItem>
    )
  }

  return (
    <div>
      <SEO
        title='2022 Election'
        titleTemplate='Halton Parents for Change'
        titleSeparator={`-`}
        description={'Halton Parents for Change - 2022 Halton Municipal Election home, candidates, profiles, recommendations, hcdsb'}
        pathname={path}
        siteLanguage='en'
        siteLocale='en-CA'
      />
      <Header
        color="dark"
        routes={dashboardRoutes}
        brand="Halton Parents for Change"
        rightLinks={<HeaderLinks />}
        fixed
        {...rest}
      />

      <Parallax image={require("assets/img/mwesigwa-joel-jHvedTh-avo-unsplash2.jpg")} small={true}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>2022 HCDSB Trustee Election</h1>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={classes.topSpace}>
          
            <h4 className={classes.description}>
              The only sure way to affect change in the HCDSB, is by electing trustees who are committed to providing a safe and inclusive environment for staff and students. Click on each candidate's card below in order to view details of their positions and values.
            </h4>
            
            <h3>
              Burlington - Ward 1 & 2
            </h3>
            <GridContainer justify="center">
            {people.filter(x=>x.Ward==='Burlington12').length ===0 &&
                <h4>No candidates have registered in this ward.</h4>
              }
              {people.filter(x=>x.Ward==='Burlington12').map(function(person){
                return (
                  candidateCard(person)
                )
              })}
            </GridContainer>
            
            <h3>
              Burlington - Ward 3 & 6
            </h3>
            <GridContainer justify="center">
            {people.filter(x=>x.Ward==='Burlington36').length ===0 &&
                <h4>No candidates have registered in this ward.</h4>
              }
              {people.filter(x=>x.Ward==='Burlington36').map(function(person){
                return (
                  candidateCard(person)
                )
              })}
            </GridContainer>

            <h3>
              Burlington - Ward 4 & 5
            </h3>
            <GridContainer justify="center">
            {people.filter(x=>x.Ward==='Burlington45').length ===0 &&
                <h4>No candidates have registered in this ward.</h4>
              }
              {people.filter(x=>x.Ward==='Burlington45').map(function(person){
                return (
                  candidateCard(person)
                )
              })}
            </GridContainer>

            <h3>
              Halton Hills
            </h3>
            <GridContainer justify="center">
            {people.filter(x=>x.Ward==='HaltonHills').length ===0 &&
                <h4>No candidates have registered in this ward.</h4>
              }
              {people.filter(x=>x.Ward==='HaltonHills').map(function(person){
                return (
                  candidateCard(person)
                )
              })}
            </GridContainer>

            <h3>
              Milton - Ward 1 & 4
            </h3>
            <GridContainer justify="center">
            {people.filter(x=>x.Ward==='Milton14').length ===0 &&
                <h4>No candidates have registered in this ward.</h4>
              }
              {people.filter(x=>x.Ward==='Milton14').map(function(person){
                return (
                  candidateCard(person)
                )
              })}
            </GridContainer>

            <h3>
              Milton - Ward 2 & 3
            </h3>
            <GridContainer justify="center">
            {people.filter(x=>x.Ward==='Milton23').length ===0 &&
                <h4>No candidates have registered in this ward.</h4>
              }
              {people.filter(x=>x.Ward==='Milton23').map(function(person){
                return (
                  candidateCard(person)
                )
              })}
            </GridContainer>

            <h3>
              Oakville - Ward 1, 2 & 3
            </h3>
            <GridContainer justify="center">
            {people.filter(x=>x.Ward==='Oakville123').length ===0 &&
                <h4>No candidates have registered in this ward.</h4>
              }
              {people.filter(x=>x.Ward==='Oakville123').map(function(person){
                return (
                  candidateCard(person)
                )
              })}
            </GridContainer>

            <h3>
              Oakville - Ward 4 & 7
            </h3>
            <GridContainer justify="center">
            {people.filter(x=>x.Ward==='Oakville47').length ===0 &&
                <h4>No candidates have registered in this ward.</h4>
              }
              {people.filter(x=>x.Ward==='Oakville47').map(function(person){
                return (
                  candidateCard(person)
                )
              })}
            </GridContainer>

            <h3>
              Oakville - Ward 5 & 6
            </h3>
            <GridContainer justify="center">
              {people.filter(x=>x.Ward==='Oakville56').length ===0 &&
                <h4>No candidates have registered in this ward.</h4>
              }
              {people.filter(x=>x.Ward==='Oakville56').map(function(person){
                return (
                  candidateCard(person)
                )
              })}
            </GridContainer>
            <img alt="Halton Parents for Change logo" className={classes.brandLogo} src={window.location.origin + '/img/HPFC_Logo_Full.png'} />
     
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
