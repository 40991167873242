import React, {} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";

import SEO from 'react-seo-component';

// Sections for this page
import MissionStatement from "./Sections/MissionStatement.js";
import WorkSection from "./Sections/WorkSection.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <SEO
        title='Halton Parents for Change'
        titleTemplate='Halton Parents for Change'
        titleSeparator={`-`}
        description={'Keep the trustees of Halton Catholic District School Board accountable to the public they serve. Maintaining voting records, initiatives and campaign outlook for 2022.'}
        pathname='https://haltonparentsforchange.ca/'
        siteLanguage='en'
        siteLocale='en-CA'
      />
      <Header
        color="dark"
        routes={dashboardRoutes}
        brand="Halton Parents for Change"
        rightLinks={<HeaderLinks />}
        fixed
        {...rest}
      />
      <Parallax image={require("assets/img/mwesigwa-joel-jHvedTh-avo-unsplash2.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Parents fighting for change at HCDSB</h1>
              <h4>
                Keep the trustees of Halton Catholic District School Board accountable to the public they serve. 

                Maintaining voting records, initiatives and campaign outlook for 2022.
              </h4>
              <br />
              {/* <Button
                color="danger"
                size="lg"
                href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-play" />
                Watch video
              </Button> */}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <MissionStatement />
          <WorkSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}
