/*eslint-disable*/
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Group, HowToVote, Forum, Mail, Info, LibraryBooks, Announcement, RecordVoiceOver } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const [people, setPeople] = useState([]);
  const classes = useStyles();

  const fetchPeople = () => {

    const burlington = axios.get("https://hcdsb-strapi.fly.dev/people?Role=Trustee&City=Burlington&_sort=Name:ASC");
    const haltonHills = axios.get("https://hcdsb-strapi.fly.dev/people?Role=Trustee&City=HaltonHills&_sort=Name:ASC");
    const milton = axios.get("https://hcdsb-strapi.fly.dev/people?Role=Trustee&City=Milton&_sort=Name:ASC");
    const oakville = axios.get("https://hcdsb-strapi.fly.dev/people?Role=Trustee&City=Oakville&_sort=Name:ASC");
    axios.all([burlington, haltonHills, milton, oakville]).then(
      axios.spread((... allData) => {
        let people = [];
        people.push ("Burlington");
        people = people.concat (allData[0].data.map(x => <Link to={`../ProfilePage/${x.ShortName}`} className={classes.dropdownLink}>{x.Name}</Link>), ["Halton Hills"], allData[1].data.map(x => <Link to={`/ProfilePage/${x.ShortName}`} className={classes.dropdownLink}>{x.Name}</Link>), "Milton", allData[2].data.map(x => <Link to={`/ProfilePage/${x.ShortName}`} className={classes.dropdownLink}>{x.Name}</Link>), ["Oakville"], allData[3].data.map(x => <Link to={`/ProfilePage/${x.ShortName}`} className={classes.dropdownLink}>{x.Name}</Link>));
        setPeople(people);
      })
    );
  };
  
  useEffect(() => {
    let mounted = true;
    fetchPeople();
    return () => mounted = false;
  }, [])


  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Link to="/News" >
          <Button color="transparent" className={classes.buttonLink}>
            <LibraryBooks /> News
          </Button>
        </Link>
      </ListItem> 
      <ListItem className={classes.listItem}>
        <Link to="/Board" >
          <Button color="transparent" className={classes.buttonLink}>
            <Icon className={classes.buttonIcon}>group</Icon> Trustees
          </Button>
        </Link>
      </ListItem>         
      <ListItem className={classes.listItem}>
        <Link to="/Issues" >
          <Button color="transparent" className={classes.buttonLink}>
            <Icon className={classes.buttonIcon}>announcement</Icon> Issues
          </Button>
        </Link>
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <Link to="/Election" >
          <Button color="transparent" className={classes.buttonLink}>
            <HowToVote /> 2022 Election
          </Button>
        </Link>
      </ListItem> */}
      {/* <ListItem className={classes.listItem}>
        <Link to="/ElectionInfo" >
          <Button color="transparent" className={classes.buttonLinkNoHover}>
            <RecordVoiceOver /> Election Info Session
          </Button>
        </Link>
      </ListItem> */}
      {/* <ListItem className={classes.listItem}>
        <Link to="/Volunteer" >
          <Button color="success" className={classes.buttonLinkNoHover}>
            <HowToVote /> Volunteers
          </Button>
        </Link>
      </ListItem> */}
      <ListItem className={classes.listItem}>
        <Link to="/Contact" >
          <Button color="transparent" className={classes.buttonLink}>
            <Icon className={classes.buttonIcon}>mail</Icon> Contact
          </Button>
        </Link>
      </ListItem> 
      <ListItem className={classes.listItem}>
        <Link to="/About" >
          <Button color="transparent" className={classes.buttonLink}>
            <Info /> About Us
          </Button>
        </Link>
      </ListItem> 
    </List>
  );
}
